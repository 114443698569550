:root {
  --body-bg-color: lightgray;
  --main-font-color: #444;
  --normal-font-size: 12px;
  --small-font-size: 10px;
  --panel-bg-color: #d9edf7;
  --top-btn-bg: #d9edf7;
  --top-btn-border: #ccc;
  --top-btn-color: #444;
  --bottom-btn-bg: #5bc0de;
  --bottom-btn-color: #fff;
  --bottom-btn-border: #46b8da;
  --main-container-border: darkgrey;
  --main-div-border: #eee;
  --highlight-color: #fdff6da6;
}

.main_wrapper {
  width: 97%;
  float: right;
  border-radius: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.gradient-text {
  background: linear-gradient(to right, #4a75e2, #a85dae, #ff6b6b);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; /* Ensures compatibility for non-webkit browsers */
}

.underline {
  text-decoration: underline;
}

.text-lg {
  font-size: larger;
}

.text-blue {
  color: rgb(0, 79, 158);
}

.text-green {
  color: rgb(2, 161, 66);
}

.text-teal {
  color: #005a73;
}

.bg-lightgreen {
  background-color: #d9ffde;
}

.bg-lightblue {
  background-color: #e6fbff !important;
}

.bg-cyan {
  background-color: cyan;
}

.bg-lightyellow {
  background-color: lightyellow;
}

.bg-red {
  background-color: rgb(251, 160, 139);
}

.bg {
  background: #e6e6e6e4 !important;
}

.bg-info {
  background: #d9edf7 !important;
}
.bg-info2 {
  background: #d9edf7 !important;
  height: 50px;
  margin: 5px !important;
  font-size: larger;
  line-height: 30px;
}

.main_container {
  background: var(--main-container-border);
  padding: 8px 0;
}

input[type="text"],
input[type="number"],
input[type="date"],
select {
  font-weight: bold;
}

.main-div {
  width: 96%;
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 0 auto;
  background: #fff !important;
  float: right;
  margin-right: 8px;
}
.loader {
  width: 100px;
  aspect-ratio: 1;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  width: 40px;
  height: 40px;
  background: linear-gradient(to right, #1d2fce, #4a75e2);
  border-radius: 50%;
  animation: l7 2s infinite;
}
.loader:after {
  animation-delay: -1s;
}
@keyframes l7 {
  0% {
    transform: translate(0%, 0%);
  }
  25% {
    transform: translate(100%, 0%);
  }
  50% {
    transform: translate(100%, -50%);
  }
  75% {
    transform: translate(0%, -50%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
.mgs {
  text-align: center;
}

.msg_div {
  margin: 0 auto;
  text-align: center;
  width: 70%;
  font-weight: 500;
  margin-top: 15px;
}

.bg-header {
  border-bottom: 1px solid darkgrey;
  font-size: 20px;
  color: #444;
  text-align: left;
  padding: 15px 0 15px 25px;
  background: #d9edf7;
}

.loggedinuser {
  width: 300px;
  font-size: 14px;
  color: #444;
  background-color: aliceblue;
  text-align: left;
  padding: 10px 10px 10px 10px;
  margin: 0px 5px 0 40px;
}

.page_caption_area {
  width: 97%;
  margin: 0 auto;
  padding-top: 15px;
}

.caption_voc {
  width: 40px;
  color: #333;
  text-align: right;
  padding-right: 15px;
  margin-top: 5px;
  float: left;
}

.field_voc {
  width: 67px;
  float: left;
  text-align: center;
}

.field_voc_input {
  width: 100% !important;
  text-align: center !important;
}

.plus {
  width: 20px;
  text-align: right;
  margin-left: 1px;
  margin-right: 1px;
  float: left;
}

.plus button {
  width: 20px;
}

.minus {
  width: 20px;
  text-align: left;
  margin-left: 0px;
  margin-right: 1px;
  float: left;
}

.minus button {
  width: 20px;
}

.maxvoucher_voc {
  width: 40px;
  text-align: left;
  margin-left: 0;
  margin-right: 20px;
  float: left;
}

.field_small {
  width: 150px;
  float: left;
  text-align: center;
  margin-right: 20px;
}

.field_big {
  width: 300px;
  float: left;
  text-align: center;
  margin-right: 20px;
}

.field_small_status {
  width: 40px;
  text-align: center;
  padding: 3px 0;
  margin-bottom: 10px;
  margin-right: 10px;
  float: left;
}

.field_big_status {
  width: 300px;
  text-align: center;
  padding: 3px 0;
  margin-bottom: 10px;
  margin-right: 20px;
  border: 1px solid #ccc;
  float: left;
}

/* vouchers bottom buttons styling */
.voc_btn_bottom {
  width: 100%;
  text-align: right;
  padding-bottom: 10px;
  padding-right: 5px;
}

.voc_btn_bottom .btn {
  margin-left: 1px;
}

.white-border {
  border-left: 2px solid white !important;
  border-right: 2px solid white !important;
}

.white-border-right {
  border-right: 2px solid white !important;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.sticky-b {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: white;
}

.zIndex-2 {
  z-index: 2;
}

.zIndex-3 {
  z-index: 3;
}

.zIndex-4 {
  z-index: 4;
}

.zIndex-5 {
  z-index: 5;
}

/* vouchers bottom buttons styling */

/* forms styling */
.form_main_wrapper {
  background: #ffffff;
}

.form_main_wrapper .form_area {
  margin: 30% auto;
  border-radius: 8px;
  background: darkgray;
}

.form_main_wrapper .form_area .form_content {
  width: 100%;
  padding: 15px;
  margin: 0 auto;
}

.form_main_wrapper .form_content .logo_img {
  text-align: left;
}

.form_main_wrapper .form_content .logo_img img {
  width: 60%;
}

a.btn.btn-info.SignUp_SignIn_Btn {
  line-height: 28px;
}

.form_main_wrapper .form_area .form_content .form_inputs_area {
  background: white;
  border-radius: 8px;
  padding: 15px 25px 50px 25px;
}

.form_main_wrapper .form_area .form_content .form_inputs_area .form_error {
  height: 26px;
  font-size: 12px;
}

.SignIn_SignUp_Form_Bottom_Area {
  width: 100%;
}

.SignIn_SignUp_Form_Bottom_Area .SignIn_SignUp_Form_Left {
  text-align: left;
  float: left;
}

.SignIn_SignUp_Form_Bottom_Area .SignIn_SignUp_Form_Right {
  text-align: right;
  float: right;
}

/* forms styling */

/* statement page table styling */
/* .statement_row{
  font-size: 12px;
}
*/
.highlight {
  background: var(--highlight-color) !important;
}

.statement_row:hover {
  background: var(--highlight-color) !important;
}

.statement_row:hover td {
  background: var(--highlight-color) !important;
}

.danger-row {
  background-color: rgb(255, 188, 199);
}

.success-row {
  background-color: rgb(193, 245, 200);
}

.warning-row {
  background-color: rgb(240, 245, 193);
}

table tr,
table td,
.caption_voc,
.field_big,
.field_small input,
.field_voc,
.field_voc input,
.field_small,
.field_big_status,
.field_small_status {
  font-size: var(--main-font-color);
}

table tr td {
  padding: 2px !important;
  vertical-align: middle !important;
}

/* Light Theme */
input,
select {
  background-color: #fff;
  color: #000;
  border-radius: 3px !important;
  border: 1px solid #000; /* Optional border */
}

input:disabled,
select:disabled {
  background-color: #e9ecef;
  color: #000;
  cursor: not-allowed;
}

.fs-lg {
  font-size: larger;
}

.fs-sm {
  font-size: 10px;
}

.fs-xs {
  font-size: 8px;
}
.fs-xxs {
  font-size: 6px;
}

.header-row {
  font-size: small;
  padding: 2px;
  width: 25%;
  margin: auto;
  background: #cececec0;
}

.form-control {
  font-size: 12px !important;
  height: 26px !important;
  border-radius: 3px !important;
}

.btn {
  padding: 0px 12px !important;
  height: 26px !important;
  line-height: 25px;
  border-radius: 0 !important;
  font-size: 12px !important;
}

.maxvoc {
  padding: 0px 3px !important;
  height: 26px !important;
  outline: none;
  border: 1px solid #ccc;
  background: #d9edf7;
  border-radius: 0 !important;
  font-size: 10px !important;
}

.maxvoc:hover {
  border-color: lightgray;
}

.plusminus {
  background: #d9edf7;
  outline: none;
  border: 1px solid lightgray;
  margin-top: 2px;
  margin-right: 2px;
  padding: 4px 15px;
}

.plusminus:hover {
  border-color: #000;
}

.btn-xs {
  height: unset !important;
  padding: 0px 5px !important;
}

.btn-sm {
  height: 20px !important;
  width: 40px !important;
  padding: 0px 5px !important;
}

.btn-xs .fa {
  margin: 0px !important;
  padding: 0px !important;
}

.fa {
  font-size: 10px !important;
}

/* cash payment */
.heading_with_print {
  display: none;
}

.trial_bal_table_amounts tr td:nth-child(3),
td:nth-child(4),
td:nth-child(5),
td:nth-child(6),
td:nth-child(7),
td:nth-child(8) {
  text-align: right;
}

.trial_heading {
  text-align: center;
}

.action_without_print {
  text-align: center !important;
}

.parentinfoprintcpcr {
  display: none;
}

.css-b62m3t-container {
  text-align: left;
}

.transactions_section {
  width: 97%;
  margin-top: 15px !important;
  margin: 0px auto;
}
/* Trial formatting */
.dr-color {
  background-color: rgb(252 165 165 / 1);
}

.dr-color:hover {
  background-color: rgba(242, 118, 118, 0.724);
}

.cr-color {
  background-color: rgb(74 222 128 / 1);
}

.cr-color:hover {
  background-color: rgba(49, 205, 106, 0.81);
}

/* vouchers show / hide on web page */
.newprint {
  display: none;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

@media only screen and (max-width: 800px) {
  table,
  .table {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 520px) {
  .main_wrapper {
    width: 100%;
    /* float: none; */
    margin: 0 auto;
    border-radius: 3px;
  }

  /* plus minus and max voucher styling */
  .page_caption_area {
    padding-top: 0px;
  }

  .bg-header {
    margin-bottom: 10px;
    font-size: 15px;
    padding: 15px 10px 15px 10px !important;
  }

  .bg-header .col-md-6:nth-child(1) {
    width: 65% !important;
    float: left;
    padding: 0 !important;
    margin: 0 !important;
  }

  .bg-header .col-md-6:nth-child(2) {
    width: 35% !important;
    float: left;
    padding: 0 !important;
    margin: 0 !important;
  }

  .loggedinuser {
    width: 100%;
    font-size: 14px;
    color: #444;
    text-align: right !important;
    padding: 10px 10px 10px 10px;
    margin: 0px 5px 0 0px;
  }

  .caption_voc {
    width: 20%;
    margin-left: 0px;
    margin-bottom: 7px;
    font-size: var(--small-font-size) !important;
  }

  .field_voc {
    width: 48%;
    margin-bottom: 7px;
  }

  .maxvoucher_voc {
    width: 15%;
    padding-left: 1%;
    /* border-right: 1px solid #ccc; */
    margin-bottom: 7px;
    margin-right: 0px;
  }

  .maxvoc {
    width: 100%;
  }

  .plus {
    width: 8%;
    text-align: right;
    margin-left: 0;
    float: left;
    display: block;
  }

  .plus button {
    width: 22px;
  }

  .minus {
    width: 8%;
    text-align: center;
    margin-left: 0;
    float: left;
    display: block;
  }

  .minus button {
    width: 22px;
    padding: 0;
    margin: 0;
    line-height: 10px;
  }

  .field_small {
    width: 80%;
    float: left;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .field_big {
    width: 80%;
    float: left;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .field_small_status {
    width: 80%;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .field_big_status {
    width: 80%;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .field_voc input,
  .field_big_status,
  .field_small_status,
  .field_big .css-b62m3t-container,
  .field_small input {
    font-size: var(--small-font-size) !important;
  }

  .main-div {
    width: 96%;
    border: 1px solid #eee;
    border-radius: 5px;
    margin: 0 auto;
    background: #fff !important;
    /* padding-bottom: 50px; */
    float: right;
    margin-right: 8px;
  }

  .transactions_section {
    width: 97%;
    margin: 0px auto;
    margin-top: 0px !important;
    overflow-x: scroll;
    z-index: -1;
  }

  /* ScrollButton.css */

  .scroll-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }

  .scroll-button:hover {
    background-color: #0056b3;
  }

  .icon {
    font-size: 24px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .transactions_section::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge add Firefox */
  .transactions_section {
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Firefox */
  }

  /* tables styling on mobile screen */
  tr td,
  th {
    font-size: var(--small-font-size) !important;
    text-transform: capitalize !important;
    padding: 5px !important;
    vertical-align: middle !important;
  }

  .jvParties,
  .jvPartiesInput,
  .jvCPB,
  .jvCPBInput,
  .JVNarrationInput {
    width: 200px !important;
    font-size: var(--small-font-size) !important;
  }

  .JVDebitInput,
  .JVCreditInput {
    width: 70px !important;
    font-size: var(--small-font-size) !important;
  }

  .CPBParties,
  .CPBPartiesInput,
  .CPBIssueTo,
  .CPBNarrationInput,
  .CPBNarration {
    width: 150px !important;
    font-size: var(--small-font-size) !important;
  }

  .CPBDated,
  .CPBBankInput,
  .CPBBank {
    width: 100px !important;
    font-size: var(--small-font-size) !important;
  }

  .CPBDebit,
  .CPBCHQNo {
    width: 70px !important;
    font-size: var(--small-font-size) !important;
  }

  /* igp page styling */
  .IGPProducts {
    width: 150px !important;
    font-size: var(--small-font-size) !important;
  }

  .IGPOthers {
    width: 50px;
    font-size: var(--small-font-size) !important;
  }

  .igpproduct {
    width: 130px !important;
  }

  /* po page styling */
  .POProducts {
    width: 150px !important;
    font-size: var(--small-font-size) !important;
  }

  .POOthers {
    width: 50px;
    font-size: var(--small-font-size) !important;
  }

  .poproduct {
    width: 130px !important;
  }
}
